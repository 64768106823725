/**
 * TEATRO
 *
 * Project style fix
 */


.mr-resource {
    .n7-metadata-viewer__group-wrapper {
        .mrc-sinopsi, .mrc-sinopsi-2, .mrc-sinopsi-3 {
            .n7-metadata-viewer__item-value p {
                text-indent: 1em;
                margin-bottom: 5px;
            }
        }
    }
}
/**
 * HEADER
 *
 * Style for header, including main navigation
 */

/* ------------------------------------ *\
    HEADER
\* ------------------------------------ */
.n7-header {
    &__nav-link {
        display: flex;
        align-items: center;
        height: 100%;
        color: $color-gray-00;
    }
    
    /* Current page */
    &__nav-item.is-active {
        .n7-header__nav-label {
            color: $color-gray-00;
        }
    }

    &__title-subtitle {
        background-position: center;
        width: 140px;
    }
    
    .n7-header__mobile-menu-icon {
        color: $color-gray-00;
        font-size: 32px;
        opacity: .7;
        transform: opacity $transition-durations;

        &:hover {
            opacity: 1;
        }
    }
}

.has-transparent-header {
    .n7-header__title-subtitle {
        background-position: center;
        width: 140px;
    }
}


/* ------------------------------------ *\
    SECOND LEVEL NAV
\* ------------------------------------ */
.n7-header {
    
    &__nav-link {
        padding: 0 $space;
        &:focus {
        }

        &:hover {
            color: $color-text-hover;
            .n7-header__nav-label {
                color: $color-text-hover;
            }
        }
    }
    
    /* Has children */
    &__nav-item.has-children {
        .n7-header__nav-link {
            &:after {
                content: "\e908";
                font-family: 'n7-icon';
                font-size: $font-size-xl;
                // color: $color-text-inverse;
                color: $color-text;
                transition: color $transition-durations;
            }

            &:hover {
                &:after {
                    color: $color-gray-00;
                }
            }
        }
    }
    

    /* Sublevel (dropdown) menu */
    &__subnav-list {
        left: 0;
        border: 1px solid $color-border-normal;
    }

    &__subnav-item {
        &:last-child {
            .n7-header__subnav-link {
            }
        }
    }

    &__subnav-link {
        font-family: $font-family-headings;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        transition: all $transition-durations;
    }

    /* Current page */
    &__subnav-item.is-current {
        .n7-header__subnav-label {
            // font-weight: $font-weight-bold;
        }
    }
}

/**
 * HOMEPAGE
 *
 * Layout for homepage
 */

.mr-home {

    // Buttons
    .n7-btn {
        border-color: white;
        border-radius: 30px;
        background-color: transparent;
    }
    .n7-btn:hover {
        background-color: white;
        color: $color-main;
    }

    // Titles
    .n7-inner-title__title,
    .n7-inner-title__subtitle {
        color: white;
    }

    // Cards
    .mr-layout__collection .n7-item-preview.is-overlay.has-image .n7-item-preview__image,
    .mr-layout__collection .n7-item-preview.is-overlay.has-image .n7-item-preview__image:after {
        border-radius: 15px;
    }
}
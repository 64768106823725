//
// COLOR SCHEME
//
// Here we define all colors used in components and project
//

// ------------------------------------ //
// #COLOR-SCHEME
// ------------------------------------ //
// Grayscale (background, borders, text)
// $color-gray-00: #ffffff;
// $color-gray-01: #f7f7f7; // f7f7f7;
// $color-gray-02: #f0f0f0; // f0f0f0;
// $color-gray-03: #dddddd; // dddddd;
// $color-gray-04: #ababab; // B6B7B9; // ababab;
// $color-gray-05: #2d3439; // 494a4d;

// Brand colors
//violet version
// $color-main: #6f6680;
// $color-second: #0a0a40;

// $color-main: #0a0a40;
$color-main: #202540;
$color-second: #6f6680;
$color-third: #7e8f9b;
$color-text-hover: lighten(
$color-gray-04,
10
); /* Used for text link in hover state */

// Status colors
// $color-error: #d12c47;
// $color-warning: #f5a623;
// $color-success: #1ab66d;

// Text background color (usually a tone of yellow)
// $color-background-text: #f8f6e2;

// ------------------------------------ //
// #HIGHLIGHT-COLOR
// ------------------------------------ //
// $color-highlight: rgba(255, 251, 0, 0.18);
